<template functional>
  <main>
    <div>
      <div class="bg">
      </div>
      <h3>we are here to help you</h3>
      <router-link to="/actions" class="btn">Start Now</router-link>
    </div>
  </main>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
};
</script>

<style scoped>
  .bg {
    background-image: url('../assets/bg.min.png');
    background-repeat: no-repeat;
    background-position: center right;
    background-size: cover;
    width: 100%;
    height: 200px;
  }
  @media screen and (min-width: 700px) {
    .bg {
      height: 400px;
    }
  }
  @media screen and (min-width: 900px) {
    .bg {
      height: 600px;
    }
  }
</style>
