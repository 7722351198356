import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/actions',
    name: 'Actions',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "actions" */ '../views/Actions.vue'),
  },
  {
    path: '/complaints',
    name: 'Complaints',
    component: () => import(/* webpackChunkName: "complaint-form" */ '../views/Complaints.vue'),
  },
  {
    path: '/enquiries',
    name: 'Enquiries',
    component: () => import(/* webpackChunkName: "enquiry-form" */ '../views/Enquiries.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
