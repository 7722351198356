<template>
  <div id="app">
    <top-bar id="topbar"></top-bar>
    <router-view/>
    <Footer id="footer"  />
  </div>
</template>

<script>
import TopBar from './components/TopBar.vue';
import Footer from './components/Footer.vue';

export default {
  components: {
    TopBar,
    Footer,
  },
};
</script>

<style>
  @import url('./assets/css/main.css');
</style>
