import Vue from 'vue';
import App from './App.vue';
// import './registerServiceWorker';
import router from './router';
import store from './store';

const url = 'https://kare.kurlon.com/api/';
// const url = 'https://kare.masec-dev.ga/api/';
// const url = 'http://localhost:3000/api/';
const options = {
  headers: {
    'Content-Type': 'application/json',
    'x-kurlon-kare-key': 'sample',
  },
};

Vue.config.productionTip = false;
Vue.prototype.apiURL = url;
Vue.prototype.reqOptions = options;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
